import React, {fragment} from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

export default function Footer() {
    return (
      <StaticQuery
        query={graphql`
          query ContactCardQuery {
            file(relativePath: { eq: "united07logolight.png" }) {
              childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(maxWidth: 200, maxHeight: 200, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={data => (
          <>{/*<div title="contact" className="contact flex center column basePad">
            <h2>Let's get in touch</h2>
            <hr style={{width: '5rem'}}/>
            <br/>

            <div className="contactCard flex spaceAround">
              <div className="contactCardText">
              <h3>Christoffer Dam Larsen</h3>
              <p>CEO United 07 North</p>
              <p>christoffer@united-o7.com</p>
              <p>+47 987 65 432</p>
              </div>
              <div className="contactCardImage">
                <Img fluid={data.file.childImageSharp.fluid} />
              </div>
            </div>
          </div>
          */}
          <div title="footer" className="footer flex center basePad column">
            <div>
            <div className="flex column">
              <Link to="/"><h4>UNITED O7</h4></Link>
              <Link to="/impressum-and-data-security" activeClassName="active"><h5>Impressum and Data Security</h5></Link>
            </div>
            <div className="flex row spaceBetween">
              <div className="contactNumbers flex flexStart column">
                <h4>BRANCHES</h4>
                <Link to="/united-07-asia" activeClassName="active"><h5>Asia</h5></Link>
                <Link to="/united-07-in-singapore" activeClassName="active"><h5>Singapore</h5></Link>
                <Link to="/united-07-in-china" activeClassName="active"><h5>China</h5></Link>
                <Link to="/united-07-in-japan" activeClassName="active"><h5>Japan</h5></Link>
                <Link to="/united-07-mideast" activeClassName="active"><h5>Mideast</h5></Link>
                <Link to="/united-07-americas" activeClassName="active"><h5>Americas</h5></Link>
                <Link to="/united-07-italy" activeClassName="active"><h5>Italy</h5></Link>
                
              </div>
              <div className="footerLogo">
              <Link to="/">
                <Img fluid={data.file.childImageSharp.fluid} />
              </Link>
              </div>
            </div>
            </div>
          </div>
          </>
        )}
      />
    )
  }
